"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createScriptEle = void 0;
const createScriptEle = (id, src) => {
    return new Promise((resolve) => {
        var _a;
        const element = document.getElementsByTagName('script')[0];
        const fjs = element;
        // return if script already exists
        if (document.getElementById(id)) {
            resolve(undefined);
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = src;
        js.onload = resolve;
        if (fjs) {
            (_a = fjs.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(js, fjs);
        }
        else {
            const children = document.body.childNodes;
            document.body.insertBefore(js, children.item(children.length - 1));
        }
    });
};
exports.createScriptEle = createScriptEle;
