"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFacebookApp = void 0;
const isFacebookApp = () => {
    if (typeof window === 'undefined') {
        return false;
    }
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf('FBAN') > -1 ||
        ua.indexOf('FBAV') > -1 ||
        ua.indexOf('Instagram') > -1);
};
exports.isFacebookApp = isFacebookApp;
